import React from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "../service/Services.css";
import Navbar from "../navbar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import topPic from "../../images/services/service-top.svg";
import green from "../../images/services/greenish.png";
import greenBtm from "../../images/services/greenish-btm.svg";
import home from "../../images/homeSVG.png";
import start from "../../images/services/star.png"
import build from "../../images/services/build.png";
import hr from "../../images/services/hr.png";
import bottom from "../../images/sec-bottom.svg";
import bottom2 from "../../images/services/greenish-btm-2.svg"
import securityOne from "../../images/services/security-grp-1.png";
import insideOne from "../../images/services/inside-1.svg";
import security from "../../images/services/security.png";
import securityThree from "../../images/services/security-grp-3.png";
import medical from "../../images/services/medical.svg";
import greenBoard from "../../images/services/greenboard.svg";
import servicemapBttm from "../../images/map-btm.svg";
import FooterPage from "../footer-page/FooterPage";




const Services = () => {
const [greenLoad, setGreenLoad] = useState(true);
const handleLoad = () => {
    setGreenLoad(false);
}
    return(<>
    <div className="service_frst_container">
        <div className="service_navbar">
            <Navbar/>
        </div>
        <div className="serv_top">
            <img src={topPic} className="img-fluid" alt="Picture" ></img>
        </div>
        <div className="service_center_box">
        <div className="container">
            <h1>OUR<span className="service_highlight"> SERVICES</span></h1>
            <p><span className="service_highlight">At Jungle View Homes, </span>we offer a comprehensive range of services to ensure a seamless and secure living experience and we are proud of that. From construction to security, every detail is designed to provide our residents with peace of mind and convenience.</p>
        </div>
        </div>
    </div>
    {greenLoad && (<div className="green_load"> <Skeleton height={0}/> </div>)}
    {!greenLoad && ( <div className="service_second_container">
            <div className="frst_btm_pic">
        <img src={green} className="img-fluid" alt="Green"></img>
        </div>

        <div className="sec_btm_pic">
        <img src={greenBtm} className="img-fluid" alt="Gren-Bottom"></img>
        </div>
    </div>)}
   
    <img src={green} alt="Preloading Green"
      style={{ display: "none" }}
      onLoad={handleLoad}></img>

    <div className="construction">
        <div className="container construction_box">
            <div className="construct_1">
                <div className="one_lft">
                <h1><span className="service_highlight">CONSTRUCTION</span> <br />SERVICE</h1>
                <p>Our expertise in construction ensures that your dream home is built to perfection with precision and care.</p>
                </div>
                <div className="one_rgt"><h1>1</h1></div>
            </div>
            <div className="construct-2">
                <div className="two_lft">
                    <img src={home} className="img-fluid" alt="Home"></img>
                </div>
                <div className="two_rgt">
                    <div className="rgt_pic">
                        <img src={start} className="img-fluid" alt="Start"></img>
                    </div>
                    <h1><span className="service_highlight">ARCHITECTURE & PLANNING</span></h1>
                    <p>Thoughtful designs to maximize functionality and synchronize with the aesthetic sense while blending harmoniously with the natural surroundings.</p>
                </div>
            </div>
            <div className="construct-3">
               <div className="three_rgt">
                    <div className="three_rgt_pic">
                        <img src={build} className="img-fluid" alt="Start"></img>
                    </div>
                    <h1><span className="service_highlight">CONSTRUCTION</span></h1>
                    <p>Thoughtful designs to maximize functionality and synchronize with the aesthetic sense while blending harmoniously with the natural surroundings.</p>
                </div>
                <div className="three_lft">
                    <img src={home} className="img-fluid" alt="Home"></img>
                </div>
            </div>
            <div className="construct-4">
                <div className="four_lft">
                    <img src={home} className="img-fluid" alt="Home"></img>
                </div>
                <div className="four_rgt">
                    <div className="four_pic">
                        <img src={hr} className="img-fluid" alt="Start"></img>
                    </div>
                    <h1><span className="service_highlight">MAN POWER</span></h1>
                    <p>Thoughtful designs to maximize functionality and synchronize with the aesthetic sense while blending harmoniously with the natural surroundings.</p>
                </div>
            </div>
        </div>
        <div className="sec_btm_pic_leaf">
            <img src={bottom} className="img-fluid" alt="Bottom"></img>
        </div>
    </div>
    <div className="service_third_contatiner">
        <div className="service_top">
        <img src={bottom2} className="img-fluid" alt="Bottom"></img>
        </div>
        <div className="security_services">
            <div className="container">
                <div className="security_1">
                <div className="seurity-lft">
                <h1><span className="service_highlight">SECURITY AT YOUR</span> <br />SERVICES</h1>
                </div>
                <div className="one_rgt"><h1>2</h1></div>
                </div>
                <div className="security_2">
                    <div className="sec_2_first">
                    <div className="sec_2_first_wrapper">
                        <img src={securityOne} className="img-fluid" alt="SecurityOne"></img>
                        <div className="overlay_image">
    <img src={insideOne} alt="Overlay Image" className="img-fluid"/>
    <div className="sec_2_first_text">
        <div className="text_container_1">
            <div className="first_img">
                <img src={start} className="img-fluid" alt="Star"></img>
            </div>
            <div className="first_text">
                <h1><span className="service_highlight">CCTV</span><br/>SURVELIANCE</h1>
            </div>
        </div>
        <div className="text_container_2" >
            <p>24/7 monitoring through well placed cameras for enhanced safety.</p>
        </div>
  </div>
  </div>
  
                    </div>
                    
                    </div>
                    
                    <div className="sec_2_second">
                    <div className="sec_2_first_wrapper">

                        <img src={securityOne} className="img-fluid" alt="SecurityTwo"></img>
                        <div className="overlay_image">
    <img src={insideOne} alt="Overlay Image" className="img-fluid" />
    <div className="sec_2_first_text">
        <div className="text_container_1">
            <div className="first_img">
                <img src={security} className="img-fluid" alt="Star"></img>
            </div>
            <div className="first_text">
                <h1><span className="service_highlight">SECURITY</span><br/>PERSONNEL</h1>
            </div>
        </div>
        <div className="text_container_2" >
            <p>Trained security staff to ensure a secure and peaceful environment.</p>
        </div>
  </div>
  </div>
  </div>
                    </div>
                </div>
                <div className="security-3">
                <div className="sec_2_first_wrap_pic">
                    <div className="security_three_pic">

                <img src={securityThree} className="img-fluid" alt="SecurityOne"></img>
                </div>
                <div className="security_side_text">
                    <div className="security_center_txt">
                <div className="text_container_1">
            <div className="first_img">
                <img src={medical} className="img-fluid" alt="Star"></img>
            </div>
            <div className="first_text">
                <h1><span className="service_highlight">MEDICAL</span><br/>CENTER</h1>
            </div>
        </div>
        <div className="text_container_2" >
            <p>A dedicated medical facility for emergencies, offering immediate care and support.</p>
        </div>
        </div>

                </div>

                        <div className="overlay_image_three">
    <img src={insideOne} alt="Overlay Image" className="img-fluid" />


    </div>

    </div>

                </div>
                <div className="security-4">
<img src={greenBoard} className="img-fluid" alt="Board"></img>
<div className="green_txt">
    <h1><span className="service_highlight">At Jungle View Homes,</span></h1>
    <p>Our services are designed to create a stress-free and enjoyable living experience, allowing you to focus on living your best life, which is what that matters at the end.</p>
</div>
                </div>
            </div>
        </div>
        <div className="servicemapbtm">
            <img src={servicemapBttm} className="img-fluid" alt="Service"></img>
        </div>
    </div>
    <div className="serviceFourthContainer">
        <FooterPage/>
    </div>

   </>);
}
export default Services;