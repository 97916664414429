import React from "react";
import "../footer-page/FooterPage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import footerTopBG from "../../images/footer-page/cloud.jpg";
import topCloud from "../../images/footer-page/cloud-top.svg";
import footerMountain from "../../images/footer-page/service-mountain.svg";
import footerPath from "../../images/footer-page/service-bottom.png";
import footBtmLogo from "../../images/footer-page/btm-logo.svg";
import face from "../../images/footer-page/link-fb.png";
import instagram from "../../images/footer-page/link-instagram.png";
import uTube from "../../images/footer-page/link-tube.png";
import mail from "../../images/footer-page/mail.png";
import app from "../../images/footer-page/whatsapp.svg";
import Line1 from "../../images/footer-page/line.png";


const FooterPage = () => {
  const currentYear = new Date().getFullYear();

  

  return (
    <>
      <div className="footer_container">
        <div className="footer_top_bg img">
          <img src={footerTopBG} className="img-fluid" alt="Leaf"></img>
        </div>
        <div className="footer_top_cloud">
          <img src={topCloud} className="img-fluid" alt="Leaf"></img>
        </div>
        <div className="container footer_top_text text-center">
          <h1 className="footer_first_text">
            Ready to
            <span className="footer_highlight"> Embrace Nature?</span>
          </h1>
          <p className="footer_sec_text">
            Don’t miss your chance to secure a piece of this tranquil paradise.
          </p>
          <p className="footer_third_text">
            Contact us today for more information!
          </p>
          <div className="contact d-flex justify-content-center align-items-center text-center gap-3">
            <a href="/contact">
            <button type="button" className="footer_contact">
              CONTACT US
            </button>
            </a>
            {/* <button type="button" className="footer_schedule">
              SCHEDULE A VISIT
            </button> */}
          </div>
        </div>
        <div className="footer_mountain">
          <img src={footerMountain} className="img-fluid" alt="Mountain"
            ></img>
         
        </div>
      </div>
      <div className="footer_path">
            <img src={footerPath} className="img-fluid" alt="Footer-Path"  
            ></img>
            <div className="footer_quick_links">
        <div class="container text-center align-items-start footer_link">
          <div class=" link_column_1">
            <div className="footer_nested_row">
              <div className="footBtmLogo">
                <a href="/">
                <img src={footBtmLogo} className="img-fluid" alt="LOGO"></img></a>
              </div>
            </div>
            <div className="footer_social_link">
              <div className="footer_social_link_1">
                <a href="https://www.facebook.com/people/Jungle-View-Homes/61571291915038/"  target="_blank" 
    rel="noopener noreferrer">
                <img src={face} alt="FaceBook"></img>
                </a>
              </div>
              <div className="footer_social_link_2">
                <a href="https://www.instagram.com/jungleviewhomes/" target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt=""></img></a>
              </div>
              {/* <div className="footer_social_link_3">
                <img src={linked} alt=""></img>
              </div>
              <div className="footer_social_link_4">
                <img src={twitter} alt=""></img>
              </div> */}
              <div className="footer_social_link_5">
                <img src={uTube} alt=""></img>
              </div>
            </div>
          </div>
          <div class="link_column_2">
            <div className="footer_nested_row">
              <div className="quick_link">QUICK LINKS</div>
              <div className="link_type"><a href="/about">About Us</a></div>
              <div className="link_type"><a href="/contact">Contact</a></div>
              {/* <div className="link_type">Privacy Policy</div> */}
            </div>
          </div>
          <div class="link_column_3">
            <div className="footer_nested_row">
              <div className="email_app">
                <img src={mail} alt="E-mail"></img>
              Email AT
              </div>

              <div className="email_details">info@jungleviewhomes.com</div>
            </div>
            <div className="footer_nested_row">
              <div className="email_app">
                <img src={app} alt="WhatsApp"></img>
                General Enquiry
              </div>
              <div className="app_details">+91 8867064507</div>
            </div>
          </div>
        </div>
        <div className="container">
          <img src={Line1} className="img-fluid" alt="Line"></img>
          <div className="footer_copy">
            <div className="copy">© {currentYear} Jungle View Homes</div>
            <div className="right">All Rights Reserved</div>
          </div>
        </div>
      </div>
          </div>
    
    </>
  );
};
export default FooterPage;
