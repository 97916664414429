import React from "react";
import "../Jungle-home/JungleHome.css";
import vectorImg from "../../images/Vector.png";
import propertyImg from "../../images/property-1.png";
import birdImg from "../../images/birdsImg.png";
import topleafImg from "../../images/top-leaf.svg";
import homeImage from "../../images/homeSVG.png";
import secondBtmImg from "../../images/sec-bottom.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import topGroupImg from "../../images/about-group.svg";
import map from "../../images/map.jpg";
import mapBottom from "../../images/map-btm.svg";
import Navbar from "../navbar/Navbar";
import FooterPage from "../footer-page/FooterPage";
import { useState, useEffect, useCallback} from "react";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPageScroller from 'react-page-scroller';
import homeTree from "../../images/mountainSVG.jpg";
import Forest from "../forest/Forest";

const JungleHome = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [ismageLoading, setImageLoading] = useState(true);

  const handleImageLoad = () => {
    setImageLoading(false);
  };
  

    const paraGroup = [
    {
      para1: "Natural Beauty & Green Fresh Air",
      para2: "Live surrounded by serene landscapes and breathe in unpolluted air."
    },
    {
      para1: "Forest Beauty & Green Fresh Air",
      para2: "Live surrounded by serene landscapes and breathe in unpolluted air."
    },
    {
      para1: "Small Beauty & Green Fresh Air",
      para2: "Live surrounded by serene landscapes and breathe in unpolluted air."
    },
    {
      para1: "Where Beauty & Green Fresh Air",
      para2: "Live surrounded by serene landscapes and breathe in unpolluted air."
    },
    
  ];
  
  const handlePageChange = useCallback((number) => {
  if (number >= 0 && number < paraGroup.length) {
    setPageNumber(number);
  }
}, [paraGroup.length]);

  
  
  useEffect(() => {
    const handleWheel = (event) => {
      // Prevent default scrolling behavior
      event.preventDefault();
    };

    // Attach wheel event listener to prevent scrolling
    const pageScrollerElement = document.querySelector('.para_grp_text');
    if (pageScrollerElement) {
      pageScrollerElement.addEventListener('wheel', handleWheel, { passive: false });
    }

    // Cleanup on unmount
    return () => {
      if (pageScrollerElement) {
        pageScrollerElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
    <div className="jungle" id="home">
      {/* HOME PAGE */}
      {ismageLoading && (
      <div className="tree-skeleton">
        <Skeleton height={0}  />
      </div>
    )}
    {!ismageLoading && (
      <div className="home_container">
        <div className="home_overlay"></div>

        <div className="home_top_nav">
          <Navbar />
        </div>

        <div className="home_tree">
          <img
            src={homeTree}
            className="img-fluid"
            alt="Home Tree"
          />
        </div>

        <div className="center_text container">
          <div className="discover_text">
            <span className="discover">DISCOVER</span>
            <span className="highlight">YOUR OASIS IN NATURE</span>
          </div>
          <div className="discover_sub_text">
            <span className="discover_highlight">Jungle View Homes: </span>Where
            Serenity Meets Luxury
          </div>
          <a href="/property">
            <div className="explore_text">EXPLORE OUR PLOTS</div>
          </a>
        </div>

        <div className="btm_img_home">
          <img
            src={vectorImg}
            alt="Vector-Picture"
            className="img-fluid"
          ></img>
        </div>
      </div>
    )}

    <img
      src={homeTree}
      alt="Preloading Home Tree"
      style={{ display: "none" }}
      onLoad={handleImageLoad}
    />
      
      {/* PROPERTY INFO PAGE  */}
      <div className="property_container">
       
        <div className="property_img">
          <img src={propertyImg} alt="Property-Picture" className="img-fluid"></img>
          <div className="bird_img">
            <img src={birdImg} alt="Bird-Picture" className="img-fluid"></img>
          </div>
          <div className="property_text container">
            <div className="who_text">
              <span className="highlight">WHO</span>
              <span className="highlight_sub_text"></span>WE ARE
            </div>
            <div className="para_text">
              <p>
                At Jungle View Homes, we believe in creating a harmonious living
                experience that blends luxury with nature.{" "}
              </p>
              <p>
                Nestled in the heart of a breathtaking forest, our plots offer a
                unique opportunity to build your dream home surrounded by the
                beauty of the wild.Where Serenity Meets Luxury
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* LIFE AT PAGE  */}
      {!ismageLoading && (<div className="lifeAt_container">
        <div className="top_leaf_image">
          <img src={topleafImg} alt="Top-Leaf"></img>
        </div>
        <div className="life_txt container">
          <span className="highlight">Why Choose</span> Jungle View Homes?
        </div>
      
        <div className="life_center_box container"
        >
            
          <div className="box_align">
          <div className="vertical_box" style={{ '--items-count': paraGroup.length }}>
  {paraGroup.map((_, index) => (
    <div
      key={index}
      className={`box_scroll ${pageNumber === index ? 'active' : ''}`}
      onClick={(e) => {
        e.stopPropagation();
        handlePageChange(index);
      }}
    ></div>
  ))}
</div>

            <div className="para_grp_text">
            
          <ReactPageScroller
            pageOnChange={handlePageChange} 
            customPageNumber={pageNumber}
            scrollAnimationDuration={100} 
            // renderAllPagesOnFirstRender={false}
            containerHeight={300}
            verticalAlign="top"

          >
            {paraGroup.map((para, paraIndex) => (
              <div key={paraIndex}  >
                <p className="para1">
                  <span className="highlighted">{para.para1}</span>
                </p>
                <p className="para2">{para.para2}</p>
              </div>
            ))}
          </ReactPageScroller>
          </div>

          <div className="number">
       <h1> {pageNumber + 1} {/* Display the updated number */}</h1>
      </div>
          

</div>
          <div className="homeImg">
            <img src={homeImage} alt="Home-Picture"></img>
          </div>
        </div>
        <div className="second-btm-img">
          <img src={secondBtmImg} alt="Frame-Picture"></img>
        </div>
      </div>)}
      
      {/* PROPERTY INSIGHT */}
      <div className="insight_container">
        <Forest/>
      </div>
     
       

      {/* ABOUT US */}
      <div className="about_container">
        <div className="top_about">
          <img src={topGroupImg} alt="Group"></img>
        </div>
        <div className="map_img">
          <div className="map_top_img">
            <img src={map} alt="Map" className="img-fluid"></img>
          </div>
          <div className="map_btm_img">
            <img src={mapBottom} alt="Map-Bottom"></img>
          </div>
        </div>
        <div className="text_about_center container">
          <div className="about_text_1">Your Gateway to Nature</div>
          <div className="about_text_2">
            Located just minutes from the city, Jungle View Homes offers the
            perfect escape without sacrificing accessibility. Enjoy hiking
            trails, wildlife watching, and the serenity of forest living while
            being close to urban amenities.
          </div>
        </div>
      </div>
      {/* OUR SERVICE */}
      <div className="service_container">
        {!ismageLoading && (<FooterPage/>
)}
 </div>
    </div>
  );
};

export default JungleHome;
