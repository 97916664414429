import React from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../about/About.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../navbar/Navbar";
import aboutTopBG from "../../images/about/about-home.svg";
import aboutTopLeaf from "../../images/explore-gallery/top-leaf.svg";
import btmPathImg from "../../images/about/about-btm-top.svg";
import treeOne from "../../images/about/tree-1.svg";
import treeTwo from "../../images/about/tree-2.svg";
import treeThree from "../../images/about/tree-3.svg";
import btm from "../../images/about/btm.png";
import circle from "../../images/about/circle.svg";
import thirdTop from "../../images/about/third-left.svg";
import thirdLeft from "../../images/about/third-right.svg";
import homeOne from "../../images/about/home-one.svg";
import homeTwo from "../../images/about/home-2.svg";
import aboutMapBottom from "../../images/map-btm.svg";
import FooterPage from "../footer-page/FooterPage";


const About = () => {
  const [preLoad, setPreLoad] = useState(true);
  const handleOnLoad = () => {
    setPreLoad(false);
  }
    return(<>
    {preLoad && (<div className="pre_load"><Skeleton height={0}/></div>)}
    {!preLoad && ( <div className="about_frt_container">
    <div className="about_nav">
            <Navbar/>
          </div>
          <div className="about_top_bg">
            <img src={aboutTopBG} className="img-fluid" alt="About"></img>
          </div>
          <div className="about_leaf">
            <img src={aboutTopLeaf} className="img-fluid" alt="ABout Leaf"></img>
          </div>
          <div className="container about_text_ct text-center">
            <h1 className="about_frt_text">
              <span className="about about_highlight">Crafting Timeless Spaces,  </span>
              <span className="">One Vision at a Time</span>
            </h1>
            <p className="about_second_text">
            With over 35 years of experience in creating premium landscape designs for elite clients, Linn & Mallow has renamed the lifestyle of outdoor living. We are proud to present our latest venture, Jungle View Homes. It is an extension of our passion and love towards designing and creating Extraditionary living spaces. Our unique project combines modern architecture with breathtaking natural landscapes, offering both luxurious and harmonious property.
            </p>
          </div>
          <div className="btm_path_about">
            <img src={btmPathImg} className="img-fluid" alt="Bottom-Path"></img>
          </div>
    </div>)}
   
    <img src={aboutTopBG} alt="Preloading"
      style={{ display: "none" }}
      onLoad={handleOnLoad}></img>

    <div className="about_sec_container">
        <div className="tree_pic_tank">
            <div className="left_stem">
<img src={treeOne} className="img-fluid" alt="Tree Path"></img>
</div>
<div className="right_stem">
    <div className="right_one">
        <img src={treeTwo} className="img-fluid" alt="Tree"></img>
    </div>
    <div className="right_two">
    <img src={treeThree} className="img-fluid" alt="Tree"></img>
    </div>
</div>

    </div>
    <div className="mirror_txt">
    <div className="second_about_txt container">
    <div className="left_text">
        <div className="pic_box">
          <img src={circle} className="img-fluid"></img>
        </div>
        <div className="text_box">
            <h1>Why <br /> <span className="txt_light">Choose Us?</span></h1>
        </div>
    </div>
    <div className="right_text">
        <div className="para_First">
            <h1><span className="txt_light">Legacy of Expertise</span></h1>
            <p>35+ years of trusted experience in building premium landscape and living space design and taking them to live projects.</p>
        </div>
        <div className="para_Sec">
        <h1><span className="txt_light">Innovative Approach</span></h1>
        <p>Merging modern lifestyles with natural aesthetics and provide a breathtaking living space.</p>
        </div>

    </div>
</div>
</div>
    <div className="about_sce_bttm">
        <img src={btm} className="img-fluid" alt="Bottom"></img>
    </div>
    </div>
    <div className="about_third_container">
      <div className="two_top_images">
      
      <div className="third_top_lft">
        <img src={thirdTop} className="img-fluid"></img>
        </div>
<div className="third_top_rht">
        <img src={thirdLeft} className="img-fluid"></img>
        </div>
        </div>
        <div className="abt">

        <div className="text_img_one container">
          <div className="home_one">
        <img src={homeOne} className="img-fluid"></img></div>
        <div className="img_txt_first container">
          <h1><span className="txt_light">WHAT WE DO?</span></h1>
          <p>At Jungle View Homes, we design exceptional living spaces that maximize breathtaking landscape and valley views while offering unparalleled privacy. Our thoughtfully planned farmhouses, with roads on both sides, ensure easy access and seclusion. Each home is crafted to blend harmoniously into the natural surroundings while also maintaining the privacy of each and every individual residence.</p>
        </div>
      </div>
      <div className="text_img_two container">
        <div className="img_txt_second">
          <h1><span className="txt_light">JOIN OUR COMMUNITY</span></h1>
          <p>Join us as we redefine the art of living with Jungle View Homes—where every space tells a lifestyle of a story, and every moment feels like a treasure.</p>
        </div>
        <div className="home_two">
        <img src={homeTwo} className="img-fluid"></img>
        </div>
      </div>
      </div>

      <div className="btmAbout">
        <img src={aboutMapBottom} className="img-fluid"></img>
      </div>
      </div>
      <div className="about_fourth_container">
        <FooterPage/>
      </div>
    </>);
 }

 export default About;